import * as React from 'react';
import {
  Box,
 
  BoxProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputProps,
  FormLabelProps
} from '@chakra-ui/react';

const toCamelCase = (title: string) =>
  title.substring(0, 1).toLowerCase() + title.substring(1).replace(new RegExp('\\s', 'g'), '');

export interface IBaseInputFields extends BoxProps {
  label: string;
  id?: string;
  name?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  register: any;
  errors: any;
  inputProps?: InputProps;
  labelProps?: FormLabelProps;
}

interface IInputFieldProps extends IBaseInputFields {
  type?: string;
}

export const InputField = ({
  label,
  isRequired = false,
  isDisabled = false,
  id = toCamelCase(label),
  register,
  type = 'text',
  name = toCamelCase(label),
  errors,
  inputProps,
  labelProps,
  children,
  ...other
}: IInputFieldProps) => {
  return (
    <Box {...other}>
      <FormControl isDisabled={isDisabled} isRequired={isRequired} isInvalid={errors && errors[name]}>
        <FormLabel htmlFor={id} {...labelProps}>
          {label}
        </FormLabel>
        {children && (
          <InputGroup size={inputProps && inputProps.size ? inputProps.size : 'md'}>
            {children}
            <Input id={id} name={name} {...register} type={type} {...inputProps} />
          </InputGroup>
        )}
        {!children && <Input id={id} name={name} {...register} type={type} {...inputProps} />}
        {errors && errors[name] && <FormErrorMessage>{errors[name].message}</FormErrorMessage>}
      </FormControl>
    </Box>
  );
};
