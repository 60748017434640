import * as React from 'react';
import { Box, FormControl, FormErrorMessage, FormLabel, Textarea, TextareaProps } from '@chakra-ui/react';
import { IBaseInputFields } from '../InputField';

const toCamelCase = (title: string) =>
  title.substring(0, 1).toLowerCase() + title.substring(1).replace(new RegExp('\\s', 'g'), '');

interface ITextBoxFieldProps extends Omit<IBaseInputFields, 'inputProps'> {
  type?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  inputProps?: TextareaProps;
}

export const TextBoxField = ({
         label,
         isRequired = false,
         isDisabled = false,
         id = toCamelCase(label),
         register,
         type = 'text',
         name = toCamelCase(label),
         errors,
         inputProps,
         labelProps,
         ...other
       }: ITextBoxFieldProps) => {
         return (
           <Box {...other}>
             <FormControl isDisabled={isDisabled} isRequired={isRequired} isInvalid={errors && errors[name]}>
               <FormLabel htmlFor={id} {...labelProps}>
                 {label}
               </FormLabel>
               <Textarea id={id} name={name} {...register} type={type} {...inputProps} />
               {errors && errors[name] && <FormErrorMessage>{errors[name].message}</FormErrorMessage>}
             </FormControl>
           </Box>
         );
       };
