import * as React from 'react';
import { Box, Checkbox, CheckboxGroup, FormControl, FormErrorMessage, Stack } from '@chakra-ui/react';
import { IBaseInputFields } from '../InputField';

const toCamelCase = (title: string) =>
  title.substring(0, 1).toLowerCase() + title.substring(1).replace(new RegExp('\\s', 'g'), '');

interface ICheckboxFieldProps extends IBaseInputFields {
  isInline?: boolean;
  spacing?: number;
  options: { label: string; value: string; isDisabled?: boolean }[];
}

export const CheckboxField = ({
         label,
         isRequired = false,
         isDisabled = false,
         register,
         name = toCamelCase(label),
         errors,
         options,
         isInline = false,
         spacing = isInline ? 8 : 0,
         ...other
       }: ICheckboxFieldProps) => {
         return (
           <Box {...other}>
             <FormControl isDisabled={isDisabled} isRequired={isRequired} isInvalid={errors && errors[name]}>
               <CheckboxGroup>
                 <Stack isInline={isInline} spacing={spacing}>
                   {options.map(({ label, ...option }, index) => (
                     <Checkbox {...register} {...option} name={`${name}[${index}]`}>
                       {label}
                     </Checkbox>
                   ))}
                 </Stack>
               </CheckboxGroup>
               {errors && errors[name] && <FormErrorMessage>{errors[name].message}</FormErrorMessage>}
             </FormControl>
           </Box>
         );
       };
