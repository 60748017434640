import firebase from 'firebase/app';
import { goPro } from '../utils/handle';

class Users {
  Firestore: firebase.firestore.Firestore;

  constructor(firestore) {
    this.Firestore = firestore;
  }

  async getOwnerOf({ uid }): Promise<string> {
    const [snap, err] = await goPro(
      this.Firestore.collection('customers').doc(uid).collection('ownerof').get({ source: 'server' })
    );
    if (err) {
      throw new Error('We failed to fetch your details. We think you may be having network issues or offline');
    }
    if (snap.empty) {
      throw new Error('This user does not own any providers');
    }
    return snap.docs[0].id;
  }

  deleteLinkWithProvider({ uid, providerId }) {
    return this.Firestore.collection('customers').doc(uid).collection('providers').doc(providerId).delete();
  }
}

export default Users;
