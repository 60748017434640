import { operationsProviderNotes } from '../refs';
import Settings from '../settings';
import firebase from 'firebase/app';
import isPresentOrError from '../utils/isPresentOrError';

class Operations {
  settings: Settings;
  provider: ProviderOperations;

  constructor(settings: Settings) {
    this.settings = settings;
    this.provider = new ProviderOperations(settings);
  }
}

class ProviderOperations {
  notes: ProviderOperationsNotes;
  constructor(settings: Settings) {
    this.notes = new ProviderOperationsNotes(settings);
  }
}

class ProviderOperationsNotes {
  Firestore: firebase.firestore.Firestore;

  constructor(settings: Settings) {
    this.Firestore = settings.firestore;
  }

  onNoteSnapshot = ({ providerId }, onSnapshot: (snapshot) => void) => {
    return operationsProviderNotes(this.Firestore, providerId).orderBy('createdAt', 'desc').onSnapshot(onSnapshot);
  };

  addNote({ providerId, note }) {
    if (!note.createdAt) {
      note.createdAt = new Date();
    }
    isPresentOrError(note, 'note');
    operationsProviderNotes(this.Firestore, providerId).add(note);
  }

  deleteNote({ providerId, noteId }) {
    operationsProviderNotes(this.Firestore, providerId).doc(noteId).delete();
  }
}

export default Operations;
