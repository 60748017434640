import * as React from 'react';
import { PropsWithChildren } from 'react';
import { Box, FormControl, FormErrorMessage, FormLabel, Select, SelectProps } from '@chakra-ui/react';
import { IBaseInputFields } from '../InputField';

const toCamelCase = (title: string) =>
  title.substring(0, 1).toLowerCase() + title.substring(1).replace(new RegExp('\\s', 'g'), '');

interface ISelectProps extends Omit<IBaseInputFields, 'inputProps'> {
  variant?: 'filled' | 'outline' | 'unstyled' | 'flushed';
  inputProps?: SelectProps;
}
type ISelectFieldProps = PropsWithChildren<ISelectProps>;

export const SelectField = ({
         label,
         isRequired = false,
         isDisabled = false,
         id = toCamelCase(label),
         register,
         name = toCamelCase(label),
         errors,
         variant = 'filled',
         inputProps,
         labelProps,
         children,
         ...other
       }: ISelectFieldProps) => {
         return (
           <Box {...other}>
             <FormControl isDisabled={isDisabled} isRequired={isRequired} isInvalid={errors && errors[name]}>
               <FormLabel htmlFor={id} {...labelProps}>
                 {label}
               </FormLabel>
               <Select id={id} name={name} {...register} variant={variant} {...inputProps}>
                 {children}
               </Select>
               {errors && errors[name] && <FormErrorMessage>{errors[name].message}</FormErrorMessage>}
             </FormControl>
           </Box>
         );
       };
