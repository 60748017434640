import * as React from 'react';
import { Box, BoxProps, Flex, FlexProps, Heading, HeadingProps } from '@chakra-ui/react';

export const Panel = ({ borderWidth = '1px', rounded = 'md', bg = 'white', ...other }: BoxProps) => (
  <Box bg={bg} borderWidth={borderWidth} rounded={rounded} {...other} />
);

export const PanelHeader = ({ fontSize = 'md', px = 6, py = 4, ...other }: HeadingProps) => (
  <Heading as='h2' fontSize={fontSize} px={px} py={py} {...other} />
);

export const PanelFooter = ({ py = 4, px = 6, ...other }: FlexProps) => <Flex py={py} px={px} {...other} />;

export const PanelBody = ({ py = 2, px = 6, ...other }: BoxProps) => <Box py={py} px={px} {...other} />;
