import { basketRef } from '../refs';
import Settings from '../settings';
import firebase from 'firebase/app';

class Basket {
  settings: Settings;

  constructor(settings: Settings) {
    this.settings = settings;
  }

  basketRef({ providerId }: { providerId: string }): firebase.firestore.DocumentReference {
    return basketRef(this.settings.firestore, providerId);
  }

  addItem = ({ providerId, item }) => {
    return (
      this.basketRef({ providerId })
        // @ts-ignore
        .set({ items: this.settings.FieldValue.arrayUnion(item) }, { merge: true })
    );
  };

  removeItem = ({ providerId, item }) => {
    return (
      this.basketRef({ providerId })
        // @ts-ignore
        .update({ items: this.settings.FieldValue.arrayRemove(item) })
    );
  };

  clear = ({ providerId }) => {
    return this.basketRef({ providerId }).update({ items: [] });
  };

  onSnapshot = ({ providerId }, onSnapshot: (snapshot: any) => void) => {
    return this.basketRef({ providerId }).onSnapshot((snap) => {
      onSnapshot(snap.data());
    });
  };
}

export default Basket;
