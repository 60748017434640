import { integrationRef, integrationsRef } from '../refs';
import firebase from 'firebase/app';

class Integrations {
  Firestore: firebase.firestore.Firestore;

  constructor(firestore) {
    this.Firestore = firestore;
  }

  async all({ providerId }): Promise<firebase.firestore.QuerySnapshot> {
    return integrationsRef(this.Firestore, providerId).get({ source: 'server' });
  }

  async has({ providerId, name }) {
    return (await this.ref({ providerId, name })).exists;
  }

  async get({ providerId, name }): Promise<any> {
    return (await this.ref({ providerId, name })).data;
  }

  async ref({ providerId, name }): Promise<firebase.firestore.DocumentSnapshot> {
    return await integrationRef(this.Firestore, { providerId, name }).get();
  }

  subscribeTo = ({ providerId, name }, onSnapshot) => {
    return integrationRef(this.Firestore, { providerId, name }).onSnapshot(onSnapshot);
  };

  async set({ providerId, name, data }) {
    return integrationRef(this.Firestore, { providerId, name }).set(data);
  }
}

export default Integrations;
