import firebase from 'firebase/app';

class Transactions {
  Firestore: firebase.firestore.Firestore;

  constructor(firestore) {
    this.Firestore = firestore;
  }

  transactionsRef({ providerId }: { providerId: string }) {
    return this.Firestore.collection('providers').doc(providerId).collection('transactions');
  }

  getHistoricTransactions = async ({ providerId, size = 30 }) => {
    const txSnaps = await this.transactionsRef({ providerId })
      .orderBy('on', 'desc')
      .limit(size)
      .get({ source: 'server' });
    const txs = [];
    txSnaps.forEach((doc) => {
      const tx = doc.data();
      tx.on = tx.on.toDate();
      txs.push(tx);
    });
    return txs;
  };

  onTransactionSnapshot = ({ providerId, transactionId }, onSnapshot: (snapshot: any) => void) => {
    return this.transactionsRef({ providerId }).doc(transactionId).onSnapshot(onSnapshot);
  };
}

export default Transactions;
