import firebase from 'firebase/app';

export default class HttpApi {
  uri: string;
  Auth: firebase.auth.Auth;

  constructor(uri: string, auth: firebase.auth.Auth) {
    this.uri = uri;
    this.Auth = auth;
  }

  _parseJSON(response) {
    return new Promise((resolve) =>
      response.json().then((json) =>
        resolve({
          status: response.status,
          ok: response.ok,
          json
        })
      )
    );
  }

  async _fetchWithoutProcessing(url, options) {
    const token = await this.Auth.currentUser.getIdToken();
    const extendHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    };
    let headers = {};
    headers = Object.assign(headers, extendHeaders);
    if (options && options.headers) {
      headers = Object.assign(headers, extendHeaders, options.headers);
    }

    return fetch(this.uri + `` + url, {
      ...options,
      headers
    });
  }

  async fetch(url, options?: any) {
    return new Promise((resolve, reject) => {
      this._fetchWithoutProcessing(url, options)
        .then(this._parseJSON)
        .then((response: { status: number; ok: boolean; json: any }) => {
          if (response.ok) {
            return resolve(response.json);
          }
          // extract the error from the server's json
          return reject(response.json);
        })
        .catch((error) =>
          reject({
            message: error.message
          })
        );
    });
  }
}
