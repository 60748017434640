import { Outputter } from '@booket-uk/logging';

interface Options {
  logger: Outputter;
}

export interface KeyValueStore<T> {
  has(key: string): boolean;

  get(key: string): T;

  put(key: string, value: T);
}

export class BrowserKeyValueLowLevelStore<Y> implements KeyValueStore<Y> {
  private readonly base: string;
  private readonly logger: Outputter;

  constructor(base: string, options?: Options) {
    this.base = base;
    this.logger =
      options && options.logger
        ? options.logger
        : {
            error: () => {},
            warn: () => {},
            info: () => {},
            debug: () => {},
          };
  }

  //  Fully qualified key
  fqKey(key: string) {
    return `${this.base}--${key}`;
  }

  get(key: string): Y {
    const fqKey = this.fqKey(key);
    if (!this.has(key)) {
      this.logger.error(`[BrowserKeyValueLowLevelStore] Key not found in local key/value store: ${fqKey}`);
      throw new Error(`Key not found in local key/value store: ${fqKey}`);
    }
    this.logger.debug(
      `[BrowserKeyValueLowLevelStore] Key found in local store`,
      fqKey,
      window.localStorage.getItem(fqKey)
    );
    return JSON.parse(window.localStorage.getItem(fqKey)) as Y;
  }

  has(key: string): boolean {
    const fqKey = this.fqKey(key);
    const rv = window.localStorage.getItem(this.fqKey(key)) !== null;
    this.logger.debug(`[BrowserKeyValueLowLevelStore] ${fqKey} is ${rv ? 'in store' : 'not in store'}`);
    return rv;
  }

  put(key: string, value: Y) {
    const fqKey = this.fqKey(key);
    const jsonVal = JSON.stringify(value);
    this.logger.debug(`[BrowserKeyValueLowLevelStore] Setting key/value in local store`, fqKey, jsonVal);
    window.localStorage.setItem(fqKey, jsonVal);
  }
}

export interface BrowserKeyValueStoreOptions<Y> extends Options {
  storer?: KeyValueStore<Y>;
}

export class BrowserKeyValueStore<Y> {
  private readonly storage: KeyValueStore<Y>;
  private readonly property: string;

  constructor(base: string, property: string, options?: BrowserKeyValueStoreOptions<Y>) {
    this.property = property;
    this.storage = options && options.storer ? options.storer : new BrowserKeyValueLowLevelStore(base, options);
  }

  get(): Y {
    return this.storage.get(this.property);
  }

  getOptional(): Y | undefined {
    if (this.storage.has(this.property)) {
      return this.get();
    } else {
      return undefined;
    }
  }

  put(item: Y) {
    return this.storage.put(this.property, item);
  }
}
