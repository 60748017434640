import * as React from "react";
import styled from "@emotion/styled/macro";
import NumberFormat from "react-number-format";

//  language=SCSS
const InplaceInput = styled.input`
  & {
    border: none;
    background-color: rgba(9, 30, 66, 0.04);
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    outline: 0;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-font-smoothing: antialiased;
    appearance: none;
    height: 40px;
    font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    padding: 0 18px;
    border-radius: 4px;
    color: rgb(9, 30, 66);
    line-height: 1.4em;
    font-weight: 700;
    font-size: 20px;
    min-width: 80px;

    &:focus {
      box-shadow: inset 0 0 0 3px rgba(0, 0, 0, 0.25);
    }
  }
`;

export const EditableNumber = ({
                                 value,
                                 onChange,
                                 ...other
                               }: { value: number; onChange: (newValue: number) => void } & Omit<React.HTMLProps<HTMLInputElement>,
  "onChange" | "value" | "ref" | "defaultValue" | "type">) => {
  return (
    <NumberFormat
      decimalScale={2}
      // fixedDecimalScale={true}
      allowNegative={false}
      prefix="£"
      value={value}
      customInput={InplaceInput}
      onValueChange={(e) => onChange(e.floatValue || 0)}
      allowEmptyFormatting={true}
      size={value.toString(10).length}
      {...other}
    />
  );
};
