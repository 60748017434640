import { Outputter } from './Outputter';

export class OutputAggregator implements Outputter {
  private pushers: Outputter[];

  constructor(outputters: Outputter[]) {
    this.pushers = outputters;
  }

  doAll(fn, message: any, optionalParams: any): void {
    this.pushers.forEach((pusher) => pusher[fn](message, optionalParams));
  }

  debug(message?: any, ...optionalParams: any[]): void {
    this.doAll('debug', message, optionalParams);
  }

  error(message?: any, ...optionalParams: any[]): void {
    this.doAll('error', message, optionalParams);
  }

  info(message?: any, ...optionalParams: any[]): void {
    this.doAll('info', message, optionalParams);
  }

  warn(message?: any, ...optionalParams: any[]): void {
    this.doAll('warn', message, optionalParams);
  }
}
