const isPresentOrError = (on, field) => {
  const val = on[field];
  if (val === undefined || val === null) {
    throw new Error(`Expected a value for ${field}`);
  }

  const type = typeof val;

  switch (type) {
    case 'string':
      if (/^\s*$/.test(val)) {
        throw new Error(`Expected a value for ${field}`);
      }
      break;
    case 'number':
      if (isNaN(val)) {
        throw new Error(`Expected a value for ${field}`);
      }
      break;
  }
};

export default isPresentOrError;
