import * as React from 'react';
import { Box, FormControl, FormErrorMessage, Radio, RadioGroup } from '@chakra-ui/react';
import { IBaseInputFields } from '../InputField';

const toCamelCase = (title: string) =>
  title.substring(0, 1).toLowerCase() + title.substring(1).replace(new RegExp('\\s', 'g'), '');

interface IRadioFieldProps extends IBaseInputFields {
  isInline?: boolean;
  spacing?: number;
  options: { label: string; value: string; isDisabled?: boolean }[];
}

export const RadioField = ({
         label,
         isRequired = false,
         isDisabled = false,
         register,
         name = toCamelCase(label),
         errors,
         options,
         isInline = false,
         spacing = isInline ? 8 : 0,
         inputProps,
         ...other
       }: IRadioFieldProps) => {
         return (
           <Box {...other}>
             <FormControl isDisabled={isDisabled} isRequired={isRequired} isInvalid={errors && errors[name]}>
               <RadioGroup isInline={isInline} spacing={spacing} name={name}>
                 {options.map(({ label, ...option }) => (
                   <Radio {...register} {...inputProps} {...option}>
                     {label}
                   </Radio>
                 ))}
               </RadioGroup>
               {errors && errors[name] && <FormErrorMessage>{errors[name].message}</FormErrorMessage>}
             </FormControl>
           </Box>
         );
       };
