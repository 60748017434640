import * as React from 'react';
import { Box, BoxProps, FormControl, FormErrorMessage, FormLabel, InputProps, FormLabelProps } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

const toCamelCase = (title: string) =>
  title.substring(0, 1).toLowerCase() + title.substring(1).replace(new RegExp('\\s', 'g'), '');

export interface IBaseInputFields extends BoxProps {
  label: string;
  id?: string;
  name?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  register: any;
  errors: any;
  inputProps?: InputProps;
  labelProps?: FormLabelProps;
}

interface IChildInputFieldProps extends PropsWithChildren<IBaseInputFields> {}

export const CustomInputField = ({
  label,
  isRequired = false,
  isDisabled = false,
  id = toCamelCase(label),
  register,
  name = toCamelCase(label),
  errors,
  labelProps,
  children,
  ...other
}: IChildInputFieldProps) => {
  return (
    <Box {...other}>
      <FormControl isDisabled={isDisabled} isRequired={isRequired} isInvalid={errors && errors[name]}>
        <FormLabel htmlFor={id} {...labelProps}>
          {label}
        </FormLabel>
        {children}
        {errors && errors[name] && <FormErrorMessage>{errors[name].message}</FormErrorMessage>}
      </FormControl>
    </Box>
  );
};
