import currency from 'currency.js';
import { Booking } from '../providers/types';

export default (booking: Booking) => {
  return booking.discount.reduce((agg, discount) => {
    if (discount.type === 'FIXED') {
      return agg.subtract(currency(discount.amount));
    } else {
      return agg.subtract(agg.multiply(currency(discount.amount).divide(100)));
    }
  }, currency(booking.service.charge)).value;
};
