import { rewardsRef } from '../refs';
import Settings from '../settings';
import firebase from 'firebase/app';
import { Reward } from './types';

class Rewards {
  Firestore: firebase.firestore.Firestore;

  constructor(settings: Settings) {
    this.Firestore = settings.firestore;
  }

  onRewardsSnapshot = (
    {
      providerId,
      field = 'referredByProviderId'
    }: { providerId: string; field?: 'referredByProviderId' | 'providerId' },
    onSnapshot: (snapshot: Array<Reward>) => void
  ) => {
    return rewardsRef(this.Firestore)
      .where(field, '==', providerId)
      .onSnapshot((snaps) => {
        if (snaps.empty) {
          onSnapshot([]);
        }
        onSnapshot(
          snaps.docs.map((doc) => {
            const dat = doc.data();
            return { ...dat, on: dat.on.toDate() } as Reward;
          })
        );
      });
  };
}

export default Rewards;
