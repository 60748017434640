import { Group } from './types';
import uuid from '../utils/uuid';
import isPresentOrError from '../utils/isPresentOrError';
import { Service } from '../providers/types';
import { serviceGroupRef, serviceGroupsRef, serviceRef, servicesRef } from '../refs';
import { log } from '../utils/log';
import firebase from 'firebase/app';
import _cloneDeep from 'lodash.clonedeep';
const TAG = '[BooketJsApi - Services]';

class Services {
  Firestore: firebase.firestore.Firestore;

  constructor(firestore: firebase.firestore.Firestore) {
    this.Firestore = firestore;
  }

  async createGroup({ providerId, group }): Promise<Group> {
    log.info(`${TAG} Creating group`, group);
    try {
      if (!group.id) {
        group.id = uuid();
        log.debug(`${TAG} No id given assigning ${group.id}`, group);
      }
      isPresentOrError(group, 'name');
      const groupRef = serviceGroupRef(this.Firestore, { providerId, groupId: group.id });
      await groupRef.set(group);
      return group;
    } catch (err) {
      log.error(`${TAG} Failed to create group`, group, err);
      throw err;
    }
  }

  async deleteGroup({ providerId, groupId }) {
    log.info(`${TAG} Deleting group`, groupId);
    const services = await servicesRef(this.Firestore, providerId).where('group.id', '==', groupId).get();

    if (services.size > 0) {
      log.warn(`${TAG} Failed to delete group - group still has services`, groupId, services);
      throw new Error('Group still has services. Delete these first');
    }

    return serviceGroupRef(this.Firestore, { providerId, groupId }).delete();
  }

  async create({ providerId, groupId, service }): Promise<Service> {
    log.info(`${TAG} Creating service`, groupId, service);
    if (!service.id) {
      service.id = uuid();
      log.debug(`${TAG} No id given assigning ${service.id}`);
    }

    isPresentOrError(service, 'name');
    isPresentOrError(service, 'charge');

    try {
      const groupSnap = await serviceGroupRef(this.Firestore, { providerId, groupId }).get();
      const servicRef = serviceRef(this.Firestore, { providerId, serviceId: service.id });

      service.group = groupSnap.data();
      service.charge = parseFloat(service.charge);
      await servicRef.set(service);
      log.info(`${TAG} Created service`, groupId, service);
      return service;
    } catch (err) {
      log.error(`${TAG} Failed to create service`, service);
      throw new Error(err.message);
    }
  }

  update({ providerId, groupId, serviceId, newServiceData }) {
    log.info(`${TAG} Updating service ${serviceId}`, newServiceData);
    const isolatedService = _cloneDeep(newServiceData);

    const ref = serviceRef(this.Firestore, { providerId, serviceId });

    if (isolatedService.charge) {
      isolatedService.charge = parseFloat(isolatedService.charge);
    }

    isPresentOrError(isolatedService, 'name');
    isPresentOrError(isolatedService, 'charge');

    if (isolatedService.group && isolatedService.group.services) {
      delete isolatedService.group.services;
    }

    return ref.update(isolatedService);
  }

  async updateGroup({ providerId, serviceId, currentGroupId, newGroupId }) {
    log.info(
      `${TAG} Updating services assigned group ${serviceId}. Current group: ${currentGroupId}, New group: ${newGroupId}`
    );
    const newGroupSnap = await serviceGroupRef(this.Firestore, { providerId, groupId: newGroupId }).get();
    const currentGroupRef = serviceRef(this.Firestore, { providerId, serviceId });
    return currentGroupRef.update({ group: newGroupSnap.data() });
  }

  delete({ providerId, groupId, serviceId }) {
    log.info(`${TAG} Deleting service`, groupId, serviceId);
    const groupRef = serviceRef(this.Firestore, { providerId, serviceId });
    return groupRef.delete();
  }

  async get({ providerId, serviceId }): Promise<Service> {
    try {
      log.info(`${TAG} Fetching service`, serviceId);
      const serviceSnap = await serviceRef(this.Firestore, { providerId, serviceId }).get();
      return serviceSnap.data() as Service;
    } catch (e) {
      log.warn(`${TAG} Failed to fetch service`, serviceId, e);
      throw e;
    }
  }

  async all({ providerId }) {
    const snaps = await servicesRef(this.Firestore, providerId).get();
    return snaps.docs.map((doc) => doc.data() as Service);
  }

  async getWithGroups({ providerId }) {
    try {
      const [serviceSnap, groupSnap] = await Promise.all([
        servicesRef(this.Firestore, providerId).get(),
        serviceGroupsRef(this.Firestore, providerId).get()
      ]);
      const services = serviceSnap.docs.map((doc) => doc.data());
      const groups = groupSnap.docs.map((doc) => doc.data());

      const servicesGroupedByGroupId = services.reduce(
        (r, v, i, a, k = v.group.id) => ((r[k] || (r[k] = [])).push(v), r),
        {}
      );
      groups.forEach((group: any) => {
        group.services = servicesGroupedByGroupId[group.id];
      });
      return groups;
    } catch (e) {
      throw e;
    }
  }

  getAllServiceGroups({ providerId }): Promise<firebase.firestore.QuerySnapshot> {
    return servicesRef(this.Firestore, providerId).get();
  }

  getGroups({ providerId }): Promise<firebase.firestore.QuerySnapshot> {
    return serviceGroupsRef(this.Firestore, providerId).get();
  }

  onGroupSnapshot = ({ providerId }, onSnapshot: (DocumentSnapshot) => void) => {
    return serviceGroupsRef(this.Firestore, providerId).onSnapshot(onSnapshot);
  };

  onSnapshot = ({ providerId }, onSnapshot: (DocumentSnapshot) => void) => {
    return servicesRef(this.Firestore, providerId).onSnapshot(onSnapshot);
  };
}

export default Services;
