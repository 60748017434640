import { firestoreReceiptToBookingReceipt } from './firestoreReceipt.to.booketReceipt';
import { Receipt } from './types';
import { receiptRef } from '../refs';
import firebase from 'firebase/app';

class Receipts {
  Firestore: firebase.firestore.Firestore;

  constructor(firestore) {
    this.Firestore = firestore;
  }

  getReceipt = async ({ providerId, receiptId }: { providerId: string; receiptId: string }): Promise<Receipt> => {
    const snap = await receiptRef(this.Firestore, { providerId, receiptId }).get();
    if (snap.exists) {
      return firestoreReceiptToBookingReceipt(snap.data());
    }
    throw new Error('Receipt not found');
  };
}

export default Receipts;
