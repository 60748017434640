import * as React from "react";
import { Button as ChakraButton, ButtonProps } from '@chakra-ui/react';

export const PrimaryButton = ({ variant = 'solid', colorScheme = 'indigo', ...other }: ButtonProps) => (
  <ChakraButton variant={variant} colorScheme={colorScheme} {...other} />
);

export const OutlineButton = ({ variant = 'outline', colorScheme = 'indigo', ...other }: ButtonProps) => (
  <ChakraButton variant={variant} colorScheme={colorScheme} {...other} />
);

export const TertiaryButton = ({ variant = 'ghost', colorScheme = 'indigo', ...other }: ButtonProps) => (
  <ChakraButton variant={variant} colorScheme={colorScheme} {...other} />
);

export const LinkButton = ({ variant = 'link', colorScheme = 'indigo', ...other }: ButtonProps) => (
  <ChakraButton variant={variant} colorScheme={colorScheme} {...other} />
);
