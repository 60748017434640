import { Distributor, DistributorCategory, DistributorProduct } from './types';
import HttpApi from '../utils/HttpApi';
import qs from 'qs-lite';
import firebase from 'firebase/app';

class Distributors {
  Firestore: firebase.firestore.Firestore;
  Api: HttpApi;

  constructor(firestore, api) {
    this.Firestore = firestore;
    this.Api = api;
  }

  async getDistributors() {
    const snap = await this.Firestore.collection('distributors').get();
    const distributors = [];
    return snap.forEach((doc) => distributors.push(doc.data() as Distributor));
  }

  getProductRef({ distributorId, id }) {
    return this.Firestore.collection('distributors').doc(distributorId).collection('products').doc(id);
  }

  getProductsRef({ distributorId, limit }) {
    return this.Firestore.collection('distributors')
      .doc(distributorId)
      .collection('products')
      .limit(limit)
      .orderBy('name', 'asc');
  }

  getProductsForDistributor({ id, startAfter, limit = 100 }) {
    if (startAfter) {
      return this.getProductsRef({ distributorId: id, limit }).startAfter(startAfter);
    }
    return this.getProductsRef({ distributorId: id, limit });
  }

  getProduct({ id }) {
    return this.Firestore.collectionGroup('products').where('id', '==', id).limit(1);
  }

  createProduct({ distributorId, product }: { distributorId: string; product: DistributorProduct }) {
    return this.getProductRef({ distributorId, id: product.id }).set(product, { merge: true });
  }

  updateProduct({ distributorId, product }: { distributorId: string; product: DistributorProduct }) {
    return this.getProductRef({ distributorId, id: product.id }).update(product);
  }

  setCategories({ distributorId, categories }: { distributorId: string; categories: DistributorCategory[] }) {
    this.Firestore.collection('distributors')
      .doc(distributorId)
      .collection('categories')
      .doc('all')
      .set({ categories });
  }

  async getCategories({ distributorId }): Promise<DistributorCategory[]> {
    const categoriesSnap = await this.Firestore.collection('distributors')
      .doc(distributorId)
      .collection('categories')
      .doc('all')
      .get();

    return categoriesSnap.data().categories;
  }

  createProducts({ distributorId, products }: { distributorId: string; products: DistributorProduct[] }) {
    const batch = this.Firestore.batch();
    products.forEach((product) => {
      batch.set(
        this.Firestore.collection('distributors').doc(distributorId).collection('products').doc(product.id),
        product,
        { merge: true }
      );
    });
    return batch.commit();
  }

  findProducts(search) {
    const queryString = qs.stringify(search);
    return this.Api.fetch(`/httpDistributorsProducts?${queryString}`);
  }

  findOrder({ id }) {
    this.Firestore.collectionGroup('booket-orders').where('id', '==', id).get();
  }

  onOrdersSnapshot = ({ providerId }: { providerId?: string }, onSnapshot: (DocumentSnapshot) => void) => {
    let query = this.Firestore.collectionGroup('booket-orders');
    if (providerId) {
      query = this.Firestore.collection('providers').doc(providerId).collection('booket-orders');
    }
    return query.orderBy('createdAt').onSnapshot(onSnapshot);
  };
}

export default Distributors;
