import { DateTime } from 'luxon';
import isPresentOrError from '../utils/isPresentOrError';
import { firestoreBookingToBooketBooking } from './firestoreBooking.to.booketBooking';
import { Booking } from '../providers/types';
import { bookingRef, bookingsRef, productRef } from '../refs';
import firebase from 'firebase/app';
import Settings from "../settings";

export interface BookingsRangeQuery {
  providerId: string;
  teamMemberId?: string;
  starts: string;
  ends: string;
}

class Bookings {
  Firestore: firebase.firestore.Firestore;
  Auth: firebase.auth.Auth;
  notes: Notes;
  settings: Settings

  constructor(settings) {
    this.Firestore = settings.firestore;
    this.Auth = settings.auth;
    this.notes = new Notes(settings.firestore);
    this.settings = settings;
  }

  // Use the API /httpBookingStateUpdate for payments
  updateBookingState({
    providerId,
    bookingId,
    newState
  }: {
    providerId: string;
    bookingId: string;
    newState: 'BOOKED' | 'NO_SHOW' | 'CANCELLED';
  }) {
    return bookingRef(this.Firestore, { providerId, bookingId }).update('state', newState);
  }

  findBookingsInRangeRef({ providerId, teamMemberId, starts, ends }: BookingsRangeQuery): firebase.firestore.Query {
    const mFrom = DateTime.fromISO(starts);
    const mTo = DateTime.fromISO(ends);
    if (mFrom.diff(mTo).days > 100) {
      throw new Error('Currently we only support queries with a range of 100 days');
    }

    let query = bookingsRef(this.Firestore, providerId)
      .where('starts', '>=', mFrom.toJSDate())
      .where('starts', '<=', mTo.toJSDate());

    if (teamMemberId) {
      query = query.where('teamMemberId', '==', teamMemberId);
    }

    return query.orderBy('starts', 'asc');
  }

  async findBookingsInRange(options: BookingsRangeQuery): Promise<firebase.firestore.QuerySnapshot> {
    const ref = this.findBookingsInRangeRef(options);
    return await ref.get({ source: 'server' });
  }

  onProviderUpcomingBookings = (
    { from = DateTime.local().toISO(), providerId, limit = 10 },
    onSnapshot: (snapshot: any) => void
  ) => {
    const mFrom = DateTime.fromISO(from);
    return bookingsRef(this.Firestore, providerId)
      .where('starts', '>=', mFrom.toJSDate())
      .orderBy('starts', 'asc')
      .limit(limit)
      .onSnapshot(onSnapshot);
  };

  onProviderHistoricBookings = (
    { from = DateTime.local().toISO(), providerId, limit = 10 },
    onSnapshot: (snapshot: any) => void
  ) => {
    const mFrom = DateTime.fromISO(from);
    return bookingsRef(this.Firestore, providerId)
      .where('starts', '<=', mFrom.toJSDate())
      .orderBy('starts', 'desc')
      .limit(limit)
      .onSnapshot(onSnapshot);
  };

  onCustomerProviderUpcomingBookings = (
    { from = DateTime.local().toISO(), customerId, providerId, limit = 10 },
    onSnapshot: (snapshot: any) => void
  ) => {
    const mFrom = DateTime.fromISO(from);
    return bookingsRef(this.Firestore, providerId)
      .where('customerId', '==', customerId)
      .where('starts', '>=', mFrom.toJSDate())
      .orderBy('starts', 'asc')
      .limit(limit)
      .onSnapshot(onSnapshot);
  };

  onCustomerProviderNearbyBookings = (
    { from = DateTime.local().toISO(), state = ['BOOKED'], customerId, providerId, limit = 10 },
    onSnapshot: (snapshot: any) => void
  ) => {
    const mFrom = DateTime.fromISO(from).startOf('day');
    const mTo = DateTime.fromISO(from).endOf('day');
    return bookingsRef(this.Firestore, providerId)
      .where('customerId', '==', customerId)
      .where('state', 'in', state)
      .where('starts', '>=', mFrom.toJSDate())
      .where('starts', '<=', mTo.toJSDate())
      .orderBy('starts', 'asc')
      .limit(limit)
      .onSnapshot(onSnapshot);
  };

  onCustomerProviderHistoricBookings = (
    { from = DateTime.local().toISO(), customerId, providerId, limit = 10 },
    onSnapshot: (snapshot: any) => void
  ) => {
    const mFrom = DateTime.fromISO(from);
    return bookingsRef(this.Firestore, providerId)
      .where('customerId', '==', customerId)
      .where('starts', '<=', mFrom.toJSDate())
      .orderBy('starts', 'desc')
      .limit(limit)
      .onSnapshot(onSnapshot);
  };

  onCustomerUpcomingBookings = (
    { from = DateTime.local().toISO(), customerId, limit = 10 },
    onSnapshot: (snapshot: any) => void
  ) => {
    const mFrom = DateTime.fromISO(from);
    const customerRef = this.Firestore.collection('customers').doc(customerId);

    return customerRef
      .collection('bookings')
      .where('starts', '>=', mFrom.toJSDate())
      .orderBy('starts', 'asc')
      .limit(limit)
      .onSnapshot(onSnapshot);
  };

  onBookingsByIdSnapshot = ({ providerId, bookingIds }, onSnapshot: (snapshot: Booking[]) => void) => {
    if (!bookingIds) {
      onSnapshot([]);
      return () => {};
    }
    if (bookingIds.length == 0) {
      onSnapshot([]);
      return () => {};
    }
    return bookingsRef(this.Firestore, providerId)
      .where('id', 'in', bookingIds)
      .onSnapshot((snap) => {
        if (snap.empty) {
          onSnapshot([]);
        } else {
          onSnapshot(snap.docs.map((doc) => firestoreBookingToBooketBooking(doc.data() as any)));
        }
      });
  };

  onBookingSnapshotV2 = ({ providerId, bookingId }, onSnapshot: (snapshot: Booking) => void) => {
    return bookingRef(this.Firestore, { providerId, bookingId }).onSnapshot((snap) => {
      if (snap.exists) {
        onSnapshot(firestoreBookingToBooketBooking(snap.data()));
      }
    });
  };

  onBookingSnapshot = ({ providerId, bookingId }, onSnapshot: (snapshot: any) => void) => {
    return bookingRef(this.Firestore, { providerId, bookingId }).onSnapshot(onSnapshot);
  };

  onBookingHistorySnapshot = ({ providerId, bookingId }, onSnapshot: (snapshot: any) => void) => {
    return bookingRef(this.Firestore, { providerId, bookingId })
      .collection('history')
      .orderBy('when')
      .onSnapshot(onSnapshot);
  };

  onUpcomingOnlineBookings = ({providerId}, onSnapshot: (snapshot: Booking[]) => void) => {
    return bookingsRef(this.Firestore, providerId)
      .where('starts', '>=', new Date())
      .where("methodOfBooking", "==", "ONLINE")
      .orderBy("starts", "asc")
      .orderBy("createdAt", "desc")
      .onSnapshot((snap) => {
        if (snap.empty) {
          onSnapshot([]);
        } else {
          onSnapshot(snap.docs.map((doc) => firestoreBookingToBooketBooking(doc.data() as any)));
        }
      });

  }

  onSnapshot = (query: BookingsRangeQuery, onSnapshot: (snapshot: any) => void) => {
    return this.findBookingsInRangeRef(query).onSnapshot(onSnapshot);
  };

  addDiscount({ providerId, bookingId, discount }) {
    const parsedDiscount = {
      created: Date.now(),
      by: this.Auth.currentUser.uid,
      amount: discount.amount,
      type: discount.type.toUpperCase()
    };
    return bookingRef(this.Firestore, { providerId, bookingId }).update(
      'discount',
      this.settings.FieldValue.arrayUnion(parsedDiscount)
    );
  }

  removeDiscount({ providerId, bookingId, discount }) {
    return bookingRef(this.Firestore, { providerId, bookingId }).update(
      'discount',
      this.settings.FieldValue.arrayRemove(discount)
    );
  }

  addProduct({ providerId, bookingId, productId, quantity }) {
    const bookingR = bookingRef(this.Firestore, { providerId, bookingId });
    const productR = productRef(this.Firestore, { providerId, productId });
    return this.Firestore.runTransaction(async (transaction) => {
      const productSnap = await transaction.get(productR);
      if (productSnap.exists) {
        const product = productSnap.data();
        product.quantity = parseInt(quantity, 10);
        const newQuantity = product.currentStock - quantity;
        delete product.currentStock;
        delete product.lowStockWarning;
        transaction.update(productR, 'currentStock', newQuantity);
        transaction.update(bookingR, 'products', this.settings.FieldValue.arrayUnion(product));
      } else {
        throw new Error("Product doesn't exists");
      }
    });
  }

  removeProduct({ providerId, bookingId, productId, quantity }) {
    const bookingR = bookingRef(this.Firestore, { providerId, bookingId });
    const productR = productRef(this.Firestore, { providerId, productId });
    return this.Firestore.runTransaction(async (transaction) => {
      const productSnap = await transaction.get(productR);
      if (productSnap.exists) {
        const product = productSnap.data();
        product.quantity = parseInt(quantity, 10);

        const newQuantity = product.currentStock + quantity;
        delete product.currentStock;
        delete product.lowStockWarning;
        transaction.update(productR, 'currentStock', newQuantity);
        transaction.update(bookingR, 'products', this.settings.FieldValue.arrayRemove(product));
      } else {
        throw new Error("Product doesn't exists");
      }
    });
  }
}

class Notes {
  Firestore: firebase.firestore.Firestore;

  constructor(firestore) {
    this.Firestore = firestore;
  }

  getBookingRef = ({ providerId, bookingId }) => {
    return bookingRef(this.Firestore, { providerId, bookingId });
  };

  onNoteSnapshot = ({ providerId, bookingId }, onSnapshot: (snapshot) => void) => {
    return this.getBookingRef({ providerId, bookingId })
      .collection('notes')
      .orderBy('createdAt', 'desc')
      .onSnapshot(onSnapshot);
  };

  addNote({ providerId, bookingId, note }) {
    if (!note.createdAt) {
      note.createdAt = new Date();
    }
    isPresentOrError(note, 'note');
    this.getBookingRef({ providerId, bookingId }).collection('notes').add(note);
  }

  deleteNote({ providerId, bookingId, noteId }) {
    this.getBookingRef({ providerId, bookingId }).collection('notes').doc(noteId).delete();
  }

  updateNote({ providerId, bookingId, noteId, updates }) {
    return this.getBookingRef({ providerId, bookingId }).collection('notes').doc(noteId).update(updates);
  }
}

export default Bookings;
