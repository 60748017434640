import {
  Box,
  Button,
  ButtonProps,
  Center,
  CenterProps,
  Popover,
  PopoverContent,
  PopoverTrigger
} from "@chakra-ui/react";
import * as React from "react";
import { HiBell } from "react-icons/hi";
import { TertiaryButton } from "../Button";

const NotificationBadge = (props: CenterProps) => (
  <Center
    bg="red.500"
    color="white"
    fontSize="xs"
    fontWeight="bold"
    position="absolute"
    rounded="full"
    textAlign="center"
    top="-2px"
    insetEnd="0"
    w="16px"
    h="16px"
    {...props}
  />
);

interface INotificationBubble {
  count: number;
}

export const NotificationBubble = (props: INotificationBubble & ButtonProps) => (
  <Button
    title={`${props.count} unread notifications`}
    {...props}>
    <Box srOnly>Click to git psee {props.count} notifications</Box>
    {props.count > 0 && <NotificationBadge>{props.count}</NotificationBadge>}
    <Box as={HiBell} fontSize="lg" />
  </Button>
);

export const NotificationPopover = (props: React.PropsWithChildren<INotificationBubble>) => {
  return (
    <Popover isLazy={true} placement="bottom">
      <PopoverTrigger>
        <NotificationBubble {...props} />
      </PopoverTrigger>
      <PopoverContent boxShadow="xl" _focus={{ outline: "none" }}>
        {props.children}
      </PopoverContent>
    </Popover>
  );
};
