import Users from '../users';
import { CustomerSummary, Dashboard, Provider, ServiceGroup, TeamMember, User } from './types';
import Bookings from '../bookings';
import { DateTime } from 'luxon';
import removeUndefined from '../utils/removeUndefined';
import isPresentOrError from '../utils/isPresentOrError';
import calculatePrice from '../bookings/calculate-price.actions';
import { slugify } from '../utils/slugify';
import { providerRef, providersRef, serviceGroupRef, serviceRef } from '../refs';
import firebase from 'firebase/app';
import { goPro } from '../utils/handle';
import Settings from "../settings";
import {providerNotificationSummaryRef} from "@booket-uk/firestore-refs";

const STANDARD_HOURS = [
  { start: '0900', ends: '1200' },
  { start: '1300', ends: '1730' }
];

class Providers {
  Firestore: firebase.firestore.Firestore;
  customers: ProviderCustomers;
  bookings: Bookings;
  teamMembers: TeamMembers;
  notifications: ProviderNotifications;

  constructor(settings: Settings) {
    this.Firestore = settings.firestore;
    this.customers = new ProviderCustomers(settings.firestore);
    this.bookings = new Bookings(settings);
    this.teamMembers = new TeamMembers(settings.firestore);
    this.notifications = new ProviderNotifications(settings.firestore);
  }

  async create(provider: Partial<Provider>, context: { user: User }) {
    const { user } = context;
    provider.createdAt = new Date();
    provider.remainingSMS = 0;

    provider.slug = slugify(provider.name);
    provider.publishedHours = [
      {
        effectiveFrom: new Date('2001-01-01T00:00:00Z'),
        effectiveTo: new Date('3000-01-01T00:00:00Z'),
        hours: {
          monday: STANDARD_HOURS,
          tuesday: STANDARD_HOURS,
          wednesday: STANDARD_HOURS,
          thursday: STANDARD_HOURS,
          friday: STANDARD_HOURS
        }
      }
    ];
    provider.type = provider.type.toUpperCase() as 'MOBILE' | 'FIXED';

    const writeBatch = this.Firestore.batch();
    const providerRef = this.Firestore.collection('providers').doc();
    const documentRef = providerRef.collection('teammembers').doc(user.uid);

    const customerRef = this.Firestore.collection('customers').doc(user.uid).collection('ownerof').doc(providerRef.id);

    provider.id = providerRef.id;

    const groupRef = serviceGroupRef(this.Firestore, { providerId: provider.id, groupId: 'default-block-group' });
    const servicRef = serviceRef(this.Firestore, { providerId: provider.id, serviceId: 'default-blocking' });
    const group: ServiceGroup = {
      type: 'BLOCKING',
      name: 'Blockings',
      id: 'default-block-group',
      color: '#D33C59'
    };
    writeBatch.set(groupRef, group);
    writeBatch.set(servicRef, {
      charge: 0,
      durationOf: [{ of: 30, type: 'BUSY' }],
      group,
      id: 'default-blocking',
      isBookableOnline: 'NO',
      name: 'Blocking',
      offeredBy: [user.uid]
    });

    const teamMember = {
      uid: user.uid,
      name: user.displayName,
      displayName: user.displayName,
      email: user.email,
      workingHours: provider.publishedHours,
      isWorkingHoursSameAsOpeningHours: true,
      isAvailableForBookings: true,
      added: new Date(),
      roles: ['ADMIN'],
      status: 1,
      servicesIds: []
    } as TeamMember;

    writeBatch.set(providerRef, provider);
    writeBatch.set(documentRef, teamMember);
    writeBatch.set(customerRef, { provider: providerRef.id });
    // writeBatch.set(groupRef, {})
    return writeBatch.commit().then((res) => {
      return { id: providerRef.id };
    });
  }

  update(providerId, updates) {
    return this.Firestore.collection('providers').doc(providerId).update(updates);
  }

  async getAll(): Promise<Provider[]> {
    const providers = await providersRef(this.Firestore).get();
    return providers.docs.map((doc) => doc.data() as Provider);
  }

  async get({ providerId }): Promise<Provider> {
    const [provSnap, err] = await goPro(providerRef(this.Firestore, providerId).get({ source: 'server' }));
    if (err) {
      throw new Error('Failed to lookup provider details');
    }
    if (!provSnap.exists) {
      throw new Error(`Provider with id ${providerId} does not exist`);
    }
    return provSnap.data() as Provider;
  }

  async getBySlug({ slug }, opts?: { notFoundAction?: 'NULL' | 'ERROR' }) {
    const provSnap = await providersRef(this.Firestore).where('slug', '==', slug).limit(1).get();
    if (provSnap.size !== 1) {
      if (opts.notFoundAction === 'NULL') {
        return null;
      } else {
        throw new Error(`"${slug}" not found`);
      }
    }
    return provSnap.docs[0].data() as Provider;
  }

  async find({ slugOrId }): Promise<Provider> {
    const provider = await this.getBySlug({ slug: slugOrId }, { notFoundAction: 'NULL' });
    if (provider) {
      return provider;
    }
    const providerFromId = await this.get({ providerId: slugOrId });
    if (!providerFromId) {
      throw new Error('Unable to find provider');
    }
    return providerFromId;
  }

  async exists({ name }): Promise<boolean> {
    const snapshot = await providersRef(this.Firestore).where('name', '==', name).get();
    return !snapshot.empty;
  }

  onSnapshot({ providerId }, onSnapshot: (DocumentSnapshot) => void) {
    return providerRef(this.Firestore, providerId).onSnapshot(onSnapshot);
  }

  onDashboard = ({ providerId, by, date }, onDashSnap: (takings: Partial<Dashboard>) => void) => {
    const day = DateTime.fromISO(date);

    let takingsRef = this.Firestore.collection('providers')
      .doc(providerId)
      .collection('takings')
      .where('year', '==', day.toFormat('yyyy'));

    let startRange = day.startOf(by);
    let endRange = day.endOf(by);

    if (by === 'day') {
      takingsRef = takingsRef.where('day', '==', day.toFormat('dd'));
    }

    if (by === 'month' || by === 'day') {
      takingsRef = takingsRef.where('month', '==', day.toFormat('MM'));
    }

    const bookingQuery = {
      providerId,
      starts: startRange.toISO(),
      ends: endRange.toISO()
    };

    let unsubBookings = this.bookings.onSnapshot(bookingQuery, async (snap: firebase.firestore.QuerySnapshot) => {
      const dash = processBookings(snap);
      const takingSnap = await takingsRef.get();
      dash.takings.actual = processTakings(takingSnap);
      onDashSnap(dash);
    });

    let unsubTakings = takingsRef.onSnapshot(async (query: firebase.firestore.QuerySnapshot) => {
      const takings = processTakings(query);
      const bookingsSnap = await this.bookings.findBookingsInRangeRef(bookingQuery).get();
      const dash = processBookings(bookingsSnap);
      dash.takings.actual = takings;
      onDashSnap(dash);
    });

    return () => {
      if (unsubBookings) {
        unsubBookings();
      }
      if (unsubTakings) {
        unsubTakings();
      }
    };
  };

}

const processTakings = (query: firebase.firestore.QuerySnapshot) => {
  let sum = 0;
  if (!query.empty) {
    query.forEach((entry) => {
      sum += entry.data().total;
    });
  }
  return sum;
};

const processBookings = (snap: firebase.firestore.QuerySnapshot) => {
  const dashboard: Dashboard = {
    bookings: {
      cancelled: 0,
      complete: 0,
      remaining: 0,
      noShows: 0
    },
    takings: {
      estimated: 0
    }
  };
  if (!snap.empty) {
    snap.forEach((entry) => {
      const booking = entry.data() as any;
      if (booking.state === 'CANCELLED') {
        dashboard.bookings.cancelled += 1;
      }
      if (booking.state === 'NO_SHOW') {
        dashboard.bookings.noShows += 1;
      }
      const fromD = DateTime.fromJSDate(booking.starts.toDate());
      if (fromD <= DateTime.utc().toJSDate() && booking.state !== 'CANCELLED' && booking.state !== 'BLOCKED') {
        dashboard.bookings.complete += 1;
        dashboard.takings.estimated += calculatePrice(booking);
      }
      if (fromD >= DateTime.utc().toJSDate() && booking.state !== 'CANCELLED' && booking.state !== 'BLOCKED') {
        dashboard.bookings.remaining += 1;
        dashboard.takings.estimated += calculatePrice(booking);
      }
    });
  }
  return dashboard;
};

class ProviderNotifications {
  Firestore: firebase.firestore.Firestore;

  constructor(firestore) {
    this.Firestore = firestore;
  }

  onProviderNotificationSummarySnapshot({providerId}, onSnapshot: ({unread: number}) => void): () => void {
    return providerNotificationSummaryRef(this.Firestore, providerId).onSnapshot((snapshot) => {
      let result = {unread: 0}
      if(snapshot.exists) {
        result = snapshot.data() as any;
      }
      onSnapshot(result);
    });
  }

  async clear({providerId}) {
    return providerNotificationSummaryRef(this.Firestore, providerId).update("unread", 0);
  }

}

class ProviderCustomers {
  Firestore: firebase.firestore.Firestore;
  Users: Users;

  constructor(firestore) {
    this.Firestore = firestore;
    this.Users = new Users(firestore);
  }

  async getCustomers({ providerId }): Promise<firebase.firestore.QuerySnapshot> {
    return await this.getCustomersRef({ providerId }).get();
  }

  onCustomerSnapshot({ providerId, customerId }, onSnapshot: (customer: CustomerSummary) => void) {
    return this.getCustomerRef({ providerId, customerId }).onSnapshot((snap) => {
      onSnapshot(snap.data() as CustomerSummary);
    });
  }

  async getCustomer({ providerId, customerId }): Promise<firebase.firestore.DocumentSnapshot> {
    return await this.getCustomerRef({ providerId, customerId }).get();
  }

  getLatestCustomerNote = async ({ providerId, customerId }): Promise<any | null> => {
    try {
      const queryRef = this.getCustomerRef({ providerId, customerId })
        .collection('notes')
        .orderBy('createdAt', 'desc')
        .limit(1);

      const querySnapshot = await queryRef.get();

      if (querySnapshot.empty || querySnapshot.size === 0) {
        return null;
      }

      const notes = [];
      querySnapshot.forEach((doc) => {
        notes.push(doc.data());
      });
      return notes[0];
    } catch (err) {
      throw err;
    }
  };

  async delete({ providerId, customerId }) {
    await this.Users.deleteLinkWithProvider({ providerId, uid: customerId });
    return await this.getCustomersRef({ providerId }).doc(customerId).delete();
  }

  getCustomersRef({ providerId }): firebase.firestore.CollectionReference {
    return this.Firestore.collection('providers').doc(providerId).collection('customers');
  }

  getCustomerRef({ providerId, customerId }): firebase.firestore.DocumentReference {
    return this.Firestore.collection('providers').doc(providerId).collection('customers').doc(customerId);
  }

  onSnapshot = ({ providerId }: { providerId: string }, onSnapshot: (snapshot) => void) => {
    return this.getCustomersRef({ providerId }).orderBy('displayName', 'asc').onSnapshot(onSnapshot);
  };

  onNoteSnapshot = ({ providerId, customerId }, onSnapshot: (snapshot) => void) => {
    return this.getCustomersRef({ providerId })
      .doc(customerId)
      .collection('notes')
      .orderBy('createdAt', 'desc')
      .onSnapshot(onSnapshot);
  };

  addNote({ providerId, customerId, note }) {
    if (!note.createdAt) {
      note.createdAt = new Date();
    }
    isPresentOrError(note, 'note');
    this.getCustomersRef({ providerId }).doc(customerId).collection('notes').add(note);
  }

  deleteNote({ providerId, customerId, noteId }) {
    this.getCustomersRef({ providerId }).doc(customerId).collection('notes').doc(noteId).delete();
  }

  updateNote({ providerId, customerId, noteId, updates }) {
    this.getCustomersRef({ providerId }).doc(customerId).collection('notes').doc(noteId).update(updates);
  }

  updateCustomer({ providerId, customerId, updates }) {
    if (updates.displayName && updates.displayName.trim() === '') {
      delete updates.displayName;
    }
    this.getCustomersRef({ providerId }).doc(customerId).update(removeUndefined(updates));
  }

  updateOptOutOf({ providerId, customerId, optOutOf }) {
    this.getCustomersRef({ providerId }).doc(customerId).update('optOutOf', removeUndefined(optOutOf));
  }
}

class TeamMembers {
  Firestore: firebase.firestore.Firestore;

  constructor(firestore: firebase.firestore.Firestore) {
    this.Firestore = firestore;
  }

  getTeamMembersRef({ providerId }): firebase.firestore.CollectionReference {
    return this.Firestore.collection('providers').doc(providerId).collection('teammembers');
  }

  onSnapshot = ({ providerId }: { providerId: string }, onSnapshot: (snapshot) => void) => {
    return this.getTeamMembersRef({ providerId }).where('status', '>=', 0).onSnapshot(onSnapshot);
  };

  onTeamMemberSnapshot = (
    { providerId, teamMemberId }: { providerId: string; teamMemberId: string },
    onSnapshot: (snapshot) => void
  ) => {
    return this.getTeamMembersRef({ providerId }).doc(teamMemberId).onSnapshot(onSnapshot);
  };

  add = async ({ providerId, entry }: { providerId: string; entry: any }) => {
    const ref = this.getTeamMembersRef({ providerId }).doc();
    const ohSnap = await this.Firestore.collection('providers').doc(providerId).get();
    isPresentOrError(entry, 'name');
    const provider = ohSnap.data();
    entry.id = ref.id;
    entry.uid = ref.id;
    entry.status = 1;
    entry.added = new Date();
    entry.createdAt = new Date();
    entry.workingHours = provider.publishedHours;
    entry.isWorkingHoursSameAsOpeningHours = true;
    entry.isAvailableForBookings = false;
    return ref.set(entry);
  };

  update = ({ providerId, teamMemberId, entry }: { teamMemberId: string; providerId: string; entry: any }) => {
    return this.getTeamMembersRef({ providerId }).doc(teamMemberId).set(entry, { merge: true });
  };

  delete = ({ providerId, teamMemberId }: { providerId: string; teamMemberId: string }) => {
    return this.getTeamMembersRef({ providerId }).doc(teamMemberId).update({ status: -1, roles: [] });
  };

  updateServiceTeamMembers = async ({
    providerId,
    teamMemberId,
    services
  }: {
    providerId: string;
    teamMemberId: string;
    services: Set<string>;
  }) => {
    const serviceRef = this.Firestore.collection('providers').doc(providerId).collection('services');

    const teamMemberRef = this.Firestore.collection('providers')
      .doc(providerId)
      .collection('teammembers')
      .doc(teamMemberId);

    const [serviceQuerySnap, teamMemberSnap] = await Promise.all([serviceRef.get(), teamMemberRef.get()]);

    const teamMember = teamMemberSnap.data() as TeamMember;
    const teamMemberServicesRef = teamMemberRef.collection('services');

    const currentServiceIds = new Set(teamMember.servicesIds ? teamMember.servicesIds : []);

    const batch = this.Firestore.batch();
    serviceQuerySnap.forEach((service) => {
      if (services.has(service.id)) {
        if (!currentServiceIds.has(service.id)) {
          batch.set(teamMemberServicesRef.doc(service.id), service.data());
        }
      } else {
        batch.delete(teamMemberServicesRef.doc(service.id));
      }
      // const dat = service.data();
      // const teamMembers = Object.assign({}, dat.teamMembers);
      // if (services.has(service.id)) {
      //   teamMembers[teamMemberId] = true;
      // } else {
      //   teamMembers[teamMemberId] = false;
      // }
      // batch.set(service.ref, {teamMembers}, {merge: true});
    });
    batch.update(teamMemberRef, 'servicesIds', Array.from(services));
    return batch.commit();
  };

  onServicesSnapshot = (
    { providerId, teamMemberId }: { providerId: string; teamMemberId: string },
    onSnapshot: (snapshot) => void
  ) => {
    return this.getTeamMembersRef({ providerId }).doc(teamMemberId).collection('services').onSnapshot(onSnapshot);
  };

  getServices({
    providerId,
    teamMemberId
  }: {
    providerId: string;
    teamMemberId: string;
  }): Promise<firebase.firestore.QuerySnapshot> {
    return this.getTeamMembersRef({ providerId }).doc(teamMemberId).collection('services').get();
  }

  updateService = ({ providerId, teamMemberId, serviceId, entry }) => {
    const batch = this.Firestore.batch();
    const tmRef = this.getTeamMembersRef({ providerId }).doc(teamMemberId);
    batch.update(tmRef.collection('services').doc(serviceId), entry);
    batch.update(tmRef, 'lastModifiedAt', new Date());
    return batch.commit();
  };

  getService = ({ providerId, teamMemberId, serviceId }) => {
    return this.getTeamMembersRef({ providerId }).doc(teamMemberId).collection('services').doc(serviceId).get();
  };

  getTeamMembersWithService = async ({ providerId, serviceId }): Promise<TeamMember[]> => {
    const teamMemberSnap = await this.getTeamMembersRef({ providerId })
      .where('status', '>=', 0)
      .where('servicesIds', 'array-contains', serviceId)
      .get({ source: 'server' });

    const teamMembers: TeamMember[] = [];
    teamMemberSnap.forEach((teamMember) => {
      teamMembers.push(Object.assign({ id: teamMember.id }, teamMember.data() as TeamMember));
    });

    return teamMembers;
  };
}

export default Providers;
