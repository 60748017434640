import * as React from 'react';
import { Select } from '@chakra-ui/react';
import SelectCreateable from 'react-select/creatable';
import ReactSelect from 'react-select';
import { theme } from '../theme';
import _get from 'lodash.get';
import {useMediaSize} from "use-media-size";
import {ChevronDownIcon} from "@chakra-ui/icons";

export const inputSizes = {
         xxl: {
           fontSize: 'xxl',
           px: 4,
           height: 20,
           rounded: 'md'
         },
         xl: {
           fontSize: 'xl',
           px: 4,
           height: 16,
           rounded: 'md'
         },
         lg: {
           fontSize: 'lg',
           px: 4,
           height: 12,
           rounded: 'md'
         },
         md: {
           fontSize: 'md',
           px: 4,
           height: 10,
           rounded: 'md'
         },
         sm: {
           fontSize: 'sm',
           px: 3,
           height: 8,
           rounded: 'sm'
         }
       };

export const colors = {
  primary: theme.colors.blue['500'],
  primary75: theme.colors.blue['300'],
  primary50: theme.colors.blue['200'],
  primary25: theme.colors.gray['100'],

  danger: theme.colors.red['500'],
  dangerLight: theme.colors.red['200'],

  neutral0: theme.colors.white,
  neutral5: theme.colors.gray['50'],
  neutral10: theme.colors.gray['100'],
  neutral20: theme.colors.gray['200'],
  neutral30: theme.colors.gray['300'],
  neutral40: theme.colors.gray['400'],
  neutral50: theme.colors.gray['500'],
  neutral60: theme.colors.gray['600'],
  neutral70: theme.colors.gray['700'],
  neutral80: theme.colors.gray['800'],
  neutral90: theme.colors.gray['900']
};

const selectTheme = theme => ({ ...theme, colors });

export const selectStyles = ({ size = 'md' }: { size: string }) => {
  const inputSize = inputSizes[size];
  return {
    control: (provided, state) => ({
      ...provided,
      fontSize: theme.fontSizes[inputSize.fontSize],
      height: theme.space[inputSize.height]
    }),
    valueContainer: provided => ({
      ...provided,
      paddingLeft: '1rem'
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    option: (provided, state) => ({
      ...provided,
      minHeight: '32px',
      paddingTop: theme.space['2'],
      paddingBottom: theme.space['2'],
      textAlign: 'left',
      paddingLeft: theme.space[inputSize.px],
      paddingRight: theme.space[inputSize.px]
    }),
    menu: provided => ({
      ...provided,
      fontSize: '1rem',
      boxShadow: theme.shadows.sm,
      borderRadius: theme.radii[inputSize.rounded],
      borderColor: 'rgb(229, 231, 235)',
      borderWidth: '1px',
      borderStyle: 'solid'
    }),
    menuList: provided => ({
      ...provided,
      paddingTop: theme.space['1'],
      paddingBottom: theme.space['2']
    }),
    groupHeading: provided => ({
      ...provided,
      fontWeight: theme.fontWeights.semibold,
      fontSize: theme.fontSizes.sm,
      color: 'initial',
      textTransform: 'none',
      paddingLeft: theme.space[inputSize.px],
      paddingRight: theme.space[inputSize.px],
      paddingTop: theme.space['3'],
      paddingBottom: theme.space['2'],
      margin: 0
    }),
    group: provided => ({
      ...provided,
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: theme.colors.gray['100'],
      paddingTop: 0,
      paddingBottom: 0
    })
  };
};

export const AutoSelectCreateable = props => {
  const { styles = {}, ...other } = props;
  const newStyles = Object.assign({}, selectStyles(other), styles);
  return (
    <SelectCreateable
      closeMenuOnSelect={true}
      isSearchable={true}
      isMulti={false}
      theme={selectTheme}
      styles={newStyles}
      {...other}
      components={{
        dropdownIndicator: <ChevronDownIcon aria-hidden={true} />,
        ...other.components
      }}
    />
  );
};

const DDIndicator = () => <ChevronDownIcon aria-hidden={true}mr={2} size='20px' />;

export const AutoSelect = (props) => {
  const {isSm} = useMediaSize();
  if(isSm) {
    return <AutoSelectMobile {...props}/>
  } else {
    return <AutoSelectReactSelect {...props}/>
  }
}

export const AutoSelectReactSelect = props => {
  const { styles = {}, ...other } = props;
  const newStyles = Object.assign({}, selectStyles(other), styles);
  return (
    <ReactSelect
      closeMenuOnSelect={true}
      isSearchable={true}
      isMulti={false}
      theme={selectTheme}
      styles={newStyles}
      {...other}
      components={{
        DropdownIndicator: DDIndicator,
        ...other.components
      }}
    />
  );
};

export const AutoSelectMobile = ({ options, value, onChange, ...other }) => {
  const onChangeInternal = e => {
    e.preventDefault();
    const nextOption = _get(options, e.currentTarget[e.currentTarget.selectedIndex].getAttribute('data-id'));
    if (onChange && nextOption) {
      onChange(nextOption);
    }
  };
  return (
    <Select {...other} onChange={onChangeInternal}>
      <option>-- Select option--</option>
      {options.map((group, groupIndex) => (
        <optgroup key={group.label} label={group.label}>
          {group.options.map((option, optionIndex) => (
            <option data-id={`[${groupIndex}].options[${optionIndex}]`} value={option.location}>
              {option.label}
            </option>
          ))}
        </optgroup>
      ))}
    </Select>
  );
};
export default AutoSelect;
