import { Outputter } from './Outputter';

export class Logger implements Outputter {
  private pusher: Outputter;

  constructor(outputter: Outputter) {
    this.pusher = outputter;
  }

  debug(message?: any, ...optionalParams: any[]): void {
    this.pusher.debug(`[debug] ${message}`, optionalParams);
  }

  error(message?: any, ...optionalParams: any[]): void {
    this.pusher.error(`[error] ${message}`, optionalParams);
  }

  info(message?: any, ...optionalParams: any[]): void {
    this.pusher.info(`[info] ${message}`, optionalParams);
  }

  warn(message?: any, ...optionalParams: any[]): void {
    this.pusher.warn(`[warn] ${message}`, optionalParams);
  }
}
