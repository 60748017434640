import firebase from 'firebase/app';

// -- Providers
export const providersRef = (firestore: firebase.firestore.Firestore) => {
  return firestore.collection('providers');
};
export const providerRef = (firestore: firebase.firestore.Firestore, id: string) => {
  return providersRef(firestore).doc(id);
};

// -- Team Members
export const teamMembersRef = (firestore: firebase.firestore.Firestore, providerId: string) => {
  return providerRef(firestore, providerId).collection('teammembers');
};

export const teamMemberRef = (
  firestore: firebase.firestore.Firestore,
  { providerId, teamMemberId }: { providerId: string; teamMemberId: string }
) => {
  return teamMembersRef(firestore, providerId).doc(teamMemberId);
};

// -- Groups
export const serviceGroupsRef = (firestore: firebase.firestore.Firestore, providerId: string) => {
  return providerRef(firestore, providerId).collection('groups');
};

export const serviceGroupRef = (
  firestore: firebase.firestore.Firestore,
  { providerId, groupId }: { providerId: string; groupId: string }
) => {
  return serviceGroupsRef(firestore, providerId).doc(groupId);
};

// -- Services
export const servicesRef = (firestore: firebase.firestore.Firestore, providerId: string) => {
  return providerRef(firestore, providerId).collection('services');
};

export const serviceRef = (
  firestore: firebase.firestore.Firestore,
  { providerId, serviceId }: { providerId: string; serviceId: string }
) => {
  return servicesRef(firestore, providerId).doc(serviceId);
};

// -- Integrations
export const integrationsRef = (firestore: firebase.firestore.Firestore, providerId: string) => {
  return providerRef(firestore, providerId).collection('integrations');
};

export const integrationRef = (
  firestore: firebase.firestore.Firestore,
  { providerId, name }: { providerId: string; name: string }
) => {
  return integrationsRef(firestore, providerId).doc(name);
};

// -- Bookings
export const bookingsRef = (firestore: firebase.firestore.Firestore, providerId: string) => {
  return providerRef(firestore, providerId).collection('bookings');
};

export const bookingRef = (
  firestore: firebase.firestore.Firestore,
  { providerId, bookingId }: { providerId: string; bookingId: string }
) => {
  return bookingsRef(firestore, providerId).doc(bookingId);
};

export const receiptsRef = (firestore: firebase.firestore.Firestore, providerId: string) => {
  return providerRef(firestore, providerId).collection('receipts');
};

export const receiptRef = (
  firestore: firebase.firestore.Firestore,
  { providerId, receiptId }: { providerId: string; receiptId: string }
) => {
  return receiptsRef(firestore, providerId).doc(receiptId);
};

// -- Products
export const productsRef = (firestore: firebase.firestore.Firestore, providerId) => {
  return providerRef(firestore, providerId).collection('products');
};

export const productRef = (
  firestore: firebase.firestore.Firestore,
  { providerId, productId }: { providerId: string; productId: string }
) => {
  return productsRef(firestore, providerId).doc(productId);
};

// -- Tmp
export const tmpRef = (firestore: firebase.firestore.Firestore, providerId: string) => {
  return providerRef(firestore, providerId).collection('tmp');
};

// -- Shop
export const basketRef = (firestore: firebase.firestore.Firestore, providerId: string) => {
  return tmpRef(firestore, providerId).doc('basket');
};

// -- Operations
// -- Providers
export const operationsRef = (firestore: firebase.firestore.Firestore) => {
  return firestore.collection('operations');
};

export const operationsCrmRef = (firestore: firebase.firestore.Firestore) => {
  return operationsRef(firestore).doc('crm');
};

export const operationsProviderCrmRef = (firestore: firebase.firestore.Firestore, id: string) => {
  return operationsCrmRef(firestore).collection('crm').doc(id);
};

export const operationsProviderNotes = (firestore: firebase.firestore.Firestore, id: string) => {
  return operationsProviderCrmRef(firestore, id).collection('notes');
};

//  -- Referrals
export const rewardsRef = (firestore: firebase.firestore.Firestore) => {
  return firestore.collection('rewards');
};
