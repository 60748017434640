import { Booking } from '../providers/types';

export const firestoreBookingToBooketBooking = (bo: any): Booking => {
  bo.starts = bo.starts.toDate();
  bo.ends = bo.ends.toDate();
  bo.timings = bo.timings.map((timing) => ({
    tmId: timing.tmId,
    from: timing.from.toDate(),
    to: timing.to.toDate(),
    state: timing.state
  }));
  bo.provider.createdAt = bo.provider.createdAt.toDate();
  return bo;
};
