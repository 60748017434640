import { Product } from '../providers/types';
import uuid from '../utils/uuid';
import isPresentOrError from '../utils/isPresentOrError';
import firebase from 'firebase/app';

class Products {
  Firestore: firebase.firestore.Firestore;

  constructor(firestore: firebase.firestore.Firestore) {
    this.Firestore = firestore;
  }

  async createGroup({ providerId, group }) {
    isPresentOrError(group, 'name');

    console.log(`Creating product group for provider ${providerId}`, group);
    const groupRef = this.Firestore.collection('providers').doc(providerId).collection('product-groups').doc();

    group.id = groupRef.id;
    await groupRef.set(group);
    return group;
  }

  async create({ providerId, groupId, product }): Promise<Product> {
    product.id = uuid();
    console.log(`Creating product for provider ${providerId}`, product);

    isPresentOrError(product, 'name');
    isPresentOrError(product, 'charge');

    try {
      const productRef = this.Firestore.collection('providers').doc(providerId).collection('products').doc();

      product.id = productRef.id;
      product.charge = parseFloat(product.charge);

      await productRef.set(product);
      await this.Firestore.collection('providers')
        .doc(providerId)
        .collection('product-groups')
        .doc(groupId)
        .update(`productIds.${product.id}`, {
          sort: -1
        });

      return product;
    } catch (err) {
      throw new Error(err.message);
    }
  }

  async update({ providerId, productId, newProductData }) {
    console.log(`Updating service ${productId} with data`, newProductData);

    const ref = this.Firestore.collection('providers').doc(providerId).collection('products').doc(productId);

    isPresentOrError(newProductData, 'name');
    isPresentOrError(newProductData, 'charge');

    newProductData.charge = parseFloat(newProductData.charge);

    return ref.update(newProductData);
  }

  async delete({ providerId, groupId, productId }) {
    console.log(`Deleting product ${productId} for provider ${providerId}`);

    const productRef = this.Firestore.collection('providers').doc(providerId).collection('products').doc(productId);

    await this.Firestore.collection('providers')
      .doc(providerId)
      .collection('product-groups')
      .doc(groupId)
      .update(`productIds.${productId}`, false);

    return productRef.delete();
  }

  async get({ providerId, productId }): Promise<Product> {
    try {
      const productSnap = await this.Firestore.collection('providers')
        .doc(providerId)
        .collection('products')
        .doc(productId)
        .get({ source: 'server' });
      const product = productSnap.data() as Product;
      return product;
    } catch (e) {
      throw e;
    }
  }

  onSnapshot = ({ providerId }, onSnapshot: (DocumentSnapshot) => void) => {
    return this.Firestore.collection('providers').doc(providerId).collection('products').onSnapshot(onSnapshot);
  };

  onGroupSnapshot = ({ providerId }, onSnapshot: (DocumentSnapshot) => void) => {
    return this.Firestore.collection('providers').doc(providerId).collection('product-groups').onSnapshot(onSnapshot);
  };
}

export default Products;
