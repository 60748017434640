const removeUndefined = (obj) => {
  Object.keys(obj).forEach(
    (key) =>
      (obj[key] && typeof obj[key] === 'object' && removeUndefined(obj[key])) ||
      (obj[key] === undefined && delete obj[key])
  );
  return obj;
};

export default removeUndefined;
