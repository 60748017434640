import * as React from "react";
import { DateTime, Duration, DurationObject } from "luxon";
import { Flex, IconButton, Text } from "@chakra-ui/react";
import { HiMinus, HiPlus } from "react-icons/hi";

export const TimeAdjuster = ({
                               value,
                               onChange,
                               adjustment = { minutes: 1 },
                               isDisabled = false
                             }: {
  value: DateTime;
  isDisabled: boolean;
  onChange: (nextDate: DateTime, adjustmentMins: number) => void;
  adjustment?: DurationObject;
}) => (
  <Flex justifyContent="center" alignItems="center" bg="rgba(9,30,66,0.04)">
    <IconButton
      aria-label="Minus 1 minute"
      mr={1}
      px={2}
      disabled={isDisabled}
      onClick={() => {
        onChange(value.minus(adjustment), -Duration.fromObject(adjustment).as("minutes"));
      }}
      icon={<HiMinus />}
    />
    <Text fontSize="md" fontWeight="bold">
      {value.toLocaleString(DateTime.TIME_SIMPLE)}
    </Text>
    <IconButton
      aria-label="Plus 1 minute"
      mr={1}
      px={2}
      disabled={isDisabled}
      onClick={() => {
        onChange(value.plus(adjustment), Duration.fromObject(adjustment).as("minutes"));
      }}
      icon={<HiPlus />}
    />
  </Flex>
);
